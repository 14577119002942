<template>
  <div class="StrategicPlan">

    <div class="card">
      <div class="card-content">
        <div class="content">
          <h1>NPPC New<br />Strategic Plan</h1>
          <!-- START: Page Logo -->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="236" height="236" viewBox="0 0 236 236" id="pageLogo">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_5" data-name="Rectangle 5" width="236" height="236" fill="#6ba4b8"/>
              </clipPath>
            </defs>
            <g id="NPPC" transform="translate(-3)" opacity="0.21">
              <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(3)" clip-path="url(#clip-path)">
                <g id="Group_7" data-name="Group 7" transform="translate(0 24.451)">
                  <g id="Group_9" data-name="Group 9">
                    <path id="Path_39" data-name="Path 39" d="M69.21,115.922c0-21.84,13.329-35.073,35.093-35.073h40.462c30.789,0,46.9-13.329,55.79-40.938H92.363c-33.3,0-55.523,22.564-55.523,55.543s21.954,55.562,55.523,55.562h12.358q-3.739-.157-7.445-.686c-15.9-2.932-28.066-15.347-28.066-34.407" transform="translate(33.307 36.083)" fill="#6ba4b8"/>
                    <path id="Path_40" data-name="Path 40" d="M37.968,114c0-43.794,29.057-73.079,73.079-73.079H233.861C225.179,13.424,209.108,0,178.071,0H93.529C36.768,0,0,36.6,0,93.548,0,149.7,41.89,187.1,93.548,187.1h17.537s-9.063-.362-13.329-1.123C63.692,180.261,37.968,152.861,37.968,114" transform="translate(0)" fill="#6ba4b8"/>
                    <path id="Path_41" data-name="Path 41" d="M164.418,106.637a5.405,5.405,0,0,0-.647-3.047,3.141,3.141,0,0,1,.228-3.066c4.817-7.14,2.685-14.528-4.075-19.726,2.228-.667,4.36-2.571,3.123-3.808-1.542-1.485-3.047,1.58-6.7,1.447a43,43,0,0,0-16.6-4.627c-8.759-.743-46.917-.152-59.732,3.923A23.22,23.22,0,0,1,68.3,79.085a.551.551,0,0,0-.514.857A11.993,11.993,0,0,0,73.5,84.416a11.2,11.2,0,0,1-4.951,9.063,1.294,1.294,0,0,0-.3,1.805,1.261,1.261,0,0,0,.17.194,6.342,6.342,0,0,0,5.369,2.266c2.818-.248,7.274-.876,12.681-1.523,7.369-.914,14.89,7.445,6.874,23.82a.286.286,0,0,0,0,.343.381.381,0,0,0,.3.171H97.45a1.486,1.486,0,0,0,1.409-.971l.457-1.352s1.333.324,1.5-.838a76.339,76.339,0,0,1,4.056-14.547c1.714,8.359,2.037,9.349,1.352,10.777l-1.428,3.046a.286.286,0,0,0,0,.343.36.36,0,0,0,.3.152h3.161a1.522,1.522,0,0,0,1.371-.895s.628-1.39.933-2.171c.514,0,1.047,0,1.009-1.066,0-1.771-.3-5.9-.152-12.681,3.2-.209,7.845.7,21.345.7,15.366,0,23.116-10.682,15.366,17.4a.42.42,0,0,0,0,.3.518.518,0,0,0,.286.171h2.913a1.236,1.236,0,0,0,1.143-.876l.4-1.314a1.084,1.084,0,0,0,1.066-.724c.324-.9.952-7.616,3.027-10.815,2.551,2.018,2.78,10.32,1.219,14.909a.447.447,0,0,0,0,.343.394.394,0,0,0,.286.152h3.294a1.2,1.2,0,0,0,1.181-.781,6.114,6.114,0,0,0,.666-1.9s1.028,0,.933-.933a69.427,69.427,0,0,1-.229-10.415Z" transform="translate(61.199 66.483)" fill="#6ba4b8"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <!-- END: Page Logo -->

          <div class="PhotoOnLeft firstImage">
            <img src="../assets/capital-building-flag.jpg" alt="State house building">
          </div>
          <p>Create a <strong>pathway for change</strong>, so culture and direction may adapt to new areas. </p>

          <div class="PhotoOnRight">
            <img src="../assets/silo-and-truck.jpg" alt="Silo and Truck">
          </div>
          <p>Focus on <strong>collaboration</strong>, the <strong>cornerstone</strong> of <strong>NPPC</strong>. </p>

          <div class="PhotoOnLeft">
            <img src="../assets/HillTestimony.jpg" alt="Hill Testimony">
          </div>
          <p>Build a team of <strong>industry-relations experts</strong> to work with companies and maintain long-term relationships with key influencers, in D.C. and beyond. </p>

          <div class="PhotoOnRight">
            <img src="../assets/Family Standing Outside.png" alt="Family standing outside hoop barn">
          </div>
          <p>Evaluate <strong>state legislative efforts</strong> to determine where we can be <strong>most effective</strong> – not just with lobbyists, but by using all tools available. </p>

          <!-- START: Swoosh footer -->
          <div id="prefooter">
            <p>See everything we do for pork producers and the industry.</p>

            <a href="https://nppc.org"><button>Visit the new NPPC</button></a>

          </div>
          <div id="footer">
            <p><a onclick="window.scrollTo(0, 0);">Back to top <img src="../assets/ic_arrow_upward_24px.png" alt="Up!"></a></p>
            <div class="postfooter">
              <p>&#169; National Pork Producers Council</p>
              <p>All Rights Reserved · <a class="postfooterlink" href="https://nppc.org/terms-of-use/">Terms of Use</a> · <a class="postfooterlink" href="https://nppc.org/privacy-statement/">Privacy Policy</a></p>
            </div>
          </div>
          <!-- END: Swoosh footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StrategicPlan',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .content {
    background-color: #01426A;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    color: white;
    margin-top:-45px;
    min-width: 392px;
  }
  .StrategicPlan {
    margin: 0px;
  }
  h1{
    color: #6BA4B8;
    text-align: left;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 46pt;
    padding-top: 6vw;
    z-index: 2;
  }
  p{
    color: #6BA4B8;
    text-align: left;
    margin-top: 6vh;
    margin-bottom: 6vh;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 28pt;
    line-height: 55px;
  }
  #pageLogo {
    position: relative;
    top: -15vh;
    right: -30vw;
    z-index: 1;
  }
  .firstImage {
    margin-top: -15vh;
  }
  .PhotoOnLeft{
    text-align: left;
  }
  .PhotoOnLeft img {
    border-bottom-right-radius:250px;
    border-top-right-radius:250px;
    width: 80vw;
  }
  .PhotoOnRight{
    text-align: right;
  }
  .PhotoOnRight img {
    border-bottom-left-radius:250px;
    border-top-left-radius:250px;
    width: 80vw;
  }
  @media only screen and (min-width: 1000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:350px;
      border-top-left-radius:350px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:350px;
      border-top-right-radius:350px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 1500px) {
    .PhotoOnRight img {
      border-bottom-left-radius:450px;
      border-top-left-radius:450px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:450px;
      border-top-right-radius:450px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 2000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:550px;
      border-top-left-radius:550px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:550px;
      border-top-right-radius:550px;
      width: 80vw;
    }
  }
</style>