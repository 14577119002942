<template>
  <div id="menu">
    <div class="menuItem menuItem1" :class="{ menuItem1Selected: currentPage===0 }">
      <a href="/#/InternationalTrade" v-on:click="currentPage = 0">
        <img src="../assets/NPPC Icons_International_Trade_Transparent-light.png" v-if="currentPage==0">
        <img src="../assets/NPPC Icons_International_Trade_Transparent.png" v-else>
      </a>
    </div>
    <div class="menuItem menuItem2" :class="{ menuItem2Selected: currentPage===1 }">
      <a href="/#/EnvironmentEnergy" v-on:click="currentPage = 1">
        <img src="../assets/Environmental Energy-2.png" v-if="currentPage==1">
        <img src="../assets/Environmental Energy.png" v-else>
      </a>
    </div>
    <div class="menuItem menuItem3" :class="{ menuItem3Selected: currentPage===2 }">
      <a href="/#/MarketCommerce" v-on:click="currentPage = 2">
        <img src="../assets/Market Commerce-brown.png" v-if="currentPage==2">
        <img src="../assets/Market Commerce-1.png" v-else>
      </a>
    </div>
    <div class="menuItem menuItem4" :class="{ menuItem4Selected: currentPage===3 }">
      <a href="/#/WellBeing" v-on:click="currentPage = 3">
        <img src="../assets/Animal Health-4.png" v-if="currentPage==3">
        <img src="../assets/Animal Health-5.png" v-else>
      </a>
    </div>
    <div class="menuItem menuItem5" :class="{ menuItem5Selected: currentPage===4 }">
      <a href="/#/StrategicPlan" v-on:click="currentPage = 4">
        <img src="../assets/NPPC-5.png" v-if="currentPage==4">
        <img src="../assets/NPPC-3.png" v-else>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Menu',
    data: function() {
      return {
        currentPage: -1
      }
    },
    mounted() {
      if(this.$route.path == '/InternationalTrade' || this.$route.path == '/'){
        // International Trade
        console.log("International Trade");
        this.currentPage = 0;
      }
      if(this.$route.path == '/EnvironmentEnergy'){
        // Environment & Energy
        console.log("Environment & Energy");
        this.currentPage = 1;
      }
      if(this.$route.path == '/MarketCommerce'){
        // Market & Commerce
        console.log("Market & Commerce");
        this.currentPage = 2;
      }
      if(this.$route.path == '/WellBeing'){
        // Animal Well-being & Food Safety
        console.log("Animal Well-being & Food Safety");
        this.currentPage = 3;
      }
      if(this.$route.path == '/StrategicPlan'){
        // NPPC New Strategic Plan
        console.log("NPPC New Strategic Plan");
        this.currentPage = 4;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #menu {
    display: inline-grid;
  }
  .menuItem {
    width: 78.4px;
    height: 72.59px;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
    padding-top: 15px;
  }
  .menuItem1 {
    background-color: none;
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .menuItem2 {
    background-color: none;
    grid-column-start: 2;
    grid-column-end: 2;
  }
  .menuItem3 {
    background-color: none;
    grid-column-start: 3;
    grid-column-end: 3;
  }
  .menuItem4 {
    background-color: none;
    grid-column-start: 4;
    grid-column-end: 4;
  }
  .menuItem5 {
    background-color: none;
    grid-column-start: 5;
    grid-column-end: 5;
  }
  .menuItem1Selected {
    background-color: #01426a;
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .menuItem2Selected {
    background-color: #2C5234;
    grid-column-start: 2;
    grid-column-end: 2;
  }
  .menuItem3Selected {
    background-color: #FED141;
    grid-column-start: 3;
    grid-column-end: 3;
  }
  .menuItem4Selected {
    background-color: #C10016;
    grid-column-start: 4;
    grid-column-end: 4;
  }
  .menuItem5Selected {
    background-color: #01426A;
    grid-column-start: 5;
    grid-column-end: 5;
  }
</style>