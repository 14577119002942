<template>
  <div class="FoodSafety">

    <div class="card">
      <div class="card-content">
        <div class="content">
          <h1><span class="line">Animal</span> <span class="line">Well-being</span></h1>
          <!-- START: Page Logo -->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="236" height="236" viewBox="0 0 236 236" id="pageLogo">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_5" data-name="Rectangle 5" width="236" height="236" fill="#f5dadf"/>
              </clipPath>
            </defs>
            <g id="Animal_Health" data-name="Animal Health" transform="translate(-3)" opacity="0.21">
              <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(3)" clip-path="url(#clip-path)">
                <path id="Path_31" data-name="Path 31" d="M211.309,77.015c-.1-9.637-.452-17.977-.787-23.956-.57-9.834-9.834-13.512-14.24-15.223C177.3,30.383,130.257,10.99,113.893,2.08a16.369,16.369,0,0,0-15.97,0c-16.364,8.91-63.646,28.3-82.8,35.835C10.7,39.725,1.53,43.226.96,53.138-.672,76.052-1.971,132.99,12.584,167.763c15.1,36.248,50.173,57.332,72.339,67.717A5.535,5.535,0,1,0,89.6,225.45c-20.691-9.637-52.927-29.305-66.714-62.013S10.794,75.875,12.013,53.787c.157-1.711,1.318-3.186,7.2-5.487,20.14-7.926,67.107-27.2,83.864-36.405a4.925,4.925,0,0,1,2.616-.629,5.033,5.033,0,0,1,2.872.649c16.855,9.244,63.665,28.224,83.628,36.405,5.9,2.3,7.2,3.776,7.2,5.487.354,5.9.708,14.2.787,23.759-38.49,1.967-77.433,5.782-97.533,12.194-11.014,3.521-16.108,6.018-38.825,4.484a1.83,1.83,0,0,0-1.731,2.832,40.528,40.528,0,0,0,18.862,14.81,37.156,37.156,0,0,1-16.364,30.053,4.265,4.265,0,0,0-.433,6.628c4.563,4.13,9.047,8.359,17.7,7.493,9.382-.865,24.113-2.931,42.03-5.074,20.337-2.518,40.929,16.265,32.059,53.851a110.8,110.8,0,0,1-5.9,17.7A151.661,151.661,0,0,0,176,201.435,115.988,115.988,0,0,0,188.081,186.9,100.867,100.867,0,0,0,198.8,167.586c10.778-25.608,12.725-63.331,12.509-90.571" transform="translate(12.338 0)" fill="#f5dadf"/>
              </g>
            </g>
          </svg>
          <!-- END: Page Logo -->

          <div class="PhotoOnRight firstImage">
            <img src="../assets/Chris-Hoffman.jpg" alt="Chris Hoffman with pigs">
          </div>
          <p>Secured nearly <strong>$6 billion in funding</strong> for dozens of <strong>agricultural programs</strong> that directly benefit the pork industry, including those to prevent and prepare for foreign animal diseases, improve diagnostics and testing, and promote continued inspections at U.S. ports of entry.   </p>

          <div class="PhotoOnLeft">
            <img src="../assets/ship-in-port.jpg" alt="Ship in port">
          </div>
          <p>
            Earned <strong>USDA “protection zone” status for Puerto Rico and the U.S. Virgin Islands</strong> against African swine fever (ASF) as a way to maintain pork exports from the U.S. mainland.   
          </p>

          <div class="PhotoOnRight">
            <img src="../assets/Morris-Murphy.jpg" alt="Morris Murphy's Pig">
          </div>
          <p>Secured <strong>$500 million in CCC funds</strong> for ASF prevention and preparedness.  </p>

          <!-- START: Swoosh footer -->
          <div id="prefooter">
            <p>See everything we do for pork producers and the industry.</p>

            <a href="https://nppc.org"><button>Visit the new NPPC</button></a>

          </div>
          <div id="footer">
            <p><a onclick="window.scrollTo(0, 0);">Back to top <img src="../assets/ic_arrow_upward_24px.png" alt="Up!"></a></p>
            <div class="postfooter">
              <p>&#169; National Pork Producers Council</p>
              <p>All Rights Reserved · <a class="postfooterlink" href="https://nppc.org/terms-of-use/">Terms of Use</a> · <a class="postfooterlink" href="https://nppc.org/privacy-statement/">Privacy Policy</a></p>
            </div>
          </div>
          <!-- END: Swoosh footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FoodSafety',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .content {
    background-color: #C10016;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    color: white;
    margin-top:-45px;
    min-width: 392px;
  }
  .FoodSafety {
    margin: 0px;
  }
  h1{
    color: #FFFFFF;
    text-align: left;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 46pt;
    padding-top: 6vw;
    z-index: 2;
  }
  p{
    color: #FFFFFF;
    text-align: left;
    margin-top: 6vh;
    margin-bottom: 6vh;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 28pt;
    line-height: 55px;
  }
  #pageLogo {
    position: relative;
    top: -20vh;
    right: -30vw;
    z-index: 1;
  }
  .firstImage {
    margin-top: -15vh;
  }
  .PhotoOnLeft{
    text-align: left;
  }
  .PhotoOnLeft img {
    border-bottom-right-radius:250px;
    border-top-right-radius:250px;
    width: 80vw;
  }
  .PhotoOnRight{
    text-align: right;
  }
  .PhotoOnRight img {
    border-bottom-left-radius:250px;
    border-top-left-radius:250px;
    width: 80vw;
  }
  @media only screen and (min-width: 1000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:350px;
      border-top-left-radius:350px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:350px;
      border-top-right-radius:350px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 1500px) {
    .PhotoOnRight img {
      border-bottom-left-radius:450px;
      border-top-left-radius:450px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:450px;
      border-top-right-radius:450px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 2000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:550px;
      border-top-left-radius:550px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:550px;
      border-top-right-radius:550px;
      width: 80vw;
    }
  }
</style>