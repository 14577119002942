<template>
  <div id="app">
    <img src="./assets/NPPC Logo.png" alt="National Pork Producers Council">
    <h1 class="head-spacing"><span class="line">Our Groundbreaking</span> <span class="line">Work Goes On</span></h1>
    <p class="head-spacing"><span class="line">The list of all the ways we've shaped the</span> <span class="line"> industry is only growing.</span></p>

    <Menu />
    <router-view></router-view>
  </div>
</template>

<script>

import Menu from './components/Menu.vue';

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
  #app {
    font-family: Public Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    margin-left: -8px;
    margin-right: 0;
    width: 100vw;
    min-width: 392px;
  }
  h1.head-spacing {
    margin-right: 4vw;
    margin-left: 4vw;
  }
  p.head-spacing {
    margin-right: 6vw;
    margin-left: 6vw;
    font-size: 12pt;
  }
  #prefooter {
    background-color: white;
    color: #01426A;
    height: 260px;
    margin-top:10vh;
    clip-path: ellipse(75% 100% at 31.75% 100%);
  }
  #prefooter p {
    color: #01426A;
    text-align: center;
    font-size: 28pt;
    padding-top: 15vh;
  }
  #prefooter button {
    background-color: #C10016;
    color: white;
    font-size: 15pt;
    border-bottom-right-radius:35px;
    border-top-right-radius:35px;
    border-bottom-left-radius:35px;
    border-top-left-radius:35px;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  #footer {
    height: 100px;
    background-color: white;
    color: #01426A;
    padding-top: 250px;
  }
  #footer p {
    color: #989B9B;
    text-align: center;
    font-size: 10pt;
  }
  #footer a {
    text-decoration: none;
    color: #01426A;
    text-align: center;
    font-size: 14pt;
    font-weight: bold;
  }
  .postfooter {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 40px;
  }
  .postfooter p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .postfooterlink {
    color: #989B9B !important;
    text-align: center !important;
    font-size: 10pt !important;
    text-decoration: underline !important;
  }
  span.line {
    display: inline-block;
  }
</style>
