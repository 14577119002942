<template>
  <div class="EnvironmentalEnergy">

    <div class="card">
      <div class="card-content">
        <div class="content">
          <h1>Environment &amp; Energy</h1>
          <!-- START: Page Logo -->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="236" height="236" viewBox="0 0 236 236" id="pageLogo">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_4" data-name="Rectangle 4" width="236" height="236" fill="#c0b661"/>
              </clipPath>
            </defs>
            <g id="Environmental_Energy" data-name="Environmental Energy" transform="translate(8)" opacity="0.214">
              <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(-8)" clip-path="url(#clip-path)">
                <path id="Path_32" data-name="Path 32" d="M138.282,70.351C122.063,44.824,98.691,20.188,82.973,1.944a5.574,5.574,0,0,0-7.859-.6,5.417,5.417,0,0,0-.5.489C58.375,19.816,35.058,44.563,18.876,70.221,7.729,88.131,0,106.561,0,123.616a78.719,78.719,0,0,0,73.163,78.458v28.351a5.574,5.574,0,0,0,11.147,0V202.075A78.719,78.719,0,0,0,157.4,123.616c0-17.074-7.785-35.43-19.118-53.266M84.4,190.89V88.409a5.574,5.574,0,0,0-11.147,0V190.89A67.552,67.552,0,0,1,11.24,123.616c0-15.216,7.171-31.584,17.167-47.487,14.4-22.963,34.724-45.091,50.256-62.165,15.2,17.353,35.653,39.461,50.163,62.369,10.088,15.885,17.39,32.086,17.39,47.283a67.553,67.553,0,0,1-61.9,67.274Z" transform="translate(39.3 0)" fill="#c0b661"/>
              </g>
            </g>
          </svg>
          <!-- END: Page Logo -->

          <div class="PhotoOnRight firstImage">
            <img src="../assets/Bane-Farm.jpg" alt="Windmill on farm">
          </div>
          <p>Led a coalition of livestock groups to <strong>preserve appropriations rider exemptions</strong> from <strong>Greenhouse Gas Reporting</strong> and <strong>Title V permitting</strong> for manure management systems. </p>

          <div class="PhotoOnLeft">
            <img src="../assets/Finishing-barn.jpg" alt="Finishing barn with field in front">
          </div>
          <p>Managed a coalition involved in numerous domestic lawsuits concerning WOTUS. The Waters Advocacy Coalition <strong>engaged in development</strong> of EPA regulations <strong>rescinding an NWPR rule</strong> and <strong>finalizing a WOTUS replacement rule</strong>. </p>

          <div class="PhotoOnRight">
            <img src="../assets/Solar panel outside pig barn.png" alt="Solar panels outside of a pig barn">
          </div>
          <p><strong>Developed</strong> a <strong>comprehensive legal strategy</strong> for responding to <strong>challenge petitions</strong> attacking the <strong>carbon footprint</strong> of the U.S. pork industry. </p>

          <!-- START: Swoosh footer -->
          <div id="prefooter">
            <p>See everything we do for pork producers and the industry.</p>

            <a href="https://nppc.org"><button>Visit the new NPPC</button></a>

          </div>
          <div id="footer">
            <p><a onclick="window.scrollTo(0, 0);">Back to top <img src="../assets/ic_arrow_upward_24px.png" alt="Up!"></a></p>
            <div class="postfooter">
              <p>&#169; National Pork Producers Council</p>
              <p>All Rights Reserved · <a class="postfooterlink" href="">Terms of Use</a> · <a class="postfooterlink" href="">Privacy Policy</a></p>
            </div>
          </div>
          <!-- END: Swoosh footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnvironmentEnergy',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .content {
    background-color: #2C5234;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    color: white;
    margin-top:-45px;
    min-width: 392px;
  }
  .EnvironmentEnergy {
    margin: 0px;
  }
  h1{
    color: #C0B661;
    text-align: left;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 44pt;
    padding-top: 6vw;
    z-index: 2;
  }
  p{
    color: #C0B661;
    text-align: left;
    margin-top: 6vh;
    margin-bottom: 6vh;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 28pt;
    line-height: 55px;
  }
  #pageLogo {
    position: relative;
    top: -20vh;
    right: -30vw;
    z-index: 1;
  }
  .firstImage {
    margin-top: -15vh;
  }
  .PhotoOnLeft{
    text-align: left;
  }
  .PhotoOnLeft img {
    border-bottom-right-radius:250px;
    border-top-right-radius:250px;
    width: 80vw;
  }
  .PhotoOnRight{
    text-align: right;
  }
  .PhotoOnRight img {
    border-bottom-left-radius:250px;
    border-top-left-radius:250px;
    width: 80vw;
  }
  @media only screen and (min-width: 1000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:350px;
      border-top-left-radius:350px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:350px;
      border-top-right-radius:350px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 1500px) {
    .PhotoOnRight img {
      border-bottom-left-radius:450px;
      border-top-left-radius:450px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:450px;
      border-top-right-radius:450px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 2000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:550px;
      border-top-left-radius:550px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:550px;
      border-top-right-radius:550px;
      width: 80vw;
    }
  }
</style>