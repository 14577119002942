<template>
  <div class="MarketCommerce">

    <div class="card">
      <div class="card-content">
        <div class="content">
          <h1>Market &amp;<br />Commerce</h1>
          <!-- START: Page Logo -->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="236" height="236" viewBox="0 0 236 236" id="pageLogo">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_6" data-name="Rectangle 6" width="236" height="236" fill="#fff"/>
              </clipPath>
            </defs>
            <g id="Market_Commerce" data-name="Market Commerce" transform="translate(-1.64 -3)" opacity="0.214">
              <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(1.64 3)" clip-path="url(#clip-path)">
                <path id="Union_2" data-name="Union 2" d="M219.5,235.3h-49.1a11.866,11.866,0,0,1-11.853-11.853V28.663a4.489,4.489,0,0,1-.066-.769V19.873a4.456,4.456,0,0,1,8.911,0v7.24a4.48,4.48,0,0,1,.068.78v195.55a2.944,2.944,0,0,0,2.94,2.942h49.079a2.92,2.92,0,0,0,2.879-2.944c0-.014,0-.028,0-.042V11.853a2.943,2.943,0,0,0-2.94-2.942H176.7A4.455,4.455,0,1,1,176.7,0h42.71a11.867,11.867,0,0,1,11.853,11.853V223.381A11.83,11.83,0,0,1,219.542,235.3Zm-131.829,0a8.331,8.331,0,0,1-8.334-8.332V87.181a8.332,8.332,0,0,1,8.334-8.334h47.144a8.331,8.331,0,0,1,8.331,8.334V226.965a8.331,8.331,0,0,1-8.331,8.332Zm-79.405,0A8.264,8.264,0,0,1,0,227.031V162.508a8.265,8.265,0,0,1,8.265-8.265H55.544a8.267,8.267,0,0,1,8.265,8.265v64.523a8.265,8.265,0,0,1-8.265,8.265ZM158.7,5.169A4.233,4.233,0,1,1,162.931,9.4,4.234,4.234,0,0,1,158.7,5.169Z" transform="translate(2.016)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
              </g>
            </g>
          </svg>
          <!-- END: Page Logo -->

          <div class="PhotoOnLeft firstImage">
            <img src="../assets/andy-feliciotti-6kA9FjqUxhM-unsplash-(1).jpg" alt="State house building">
          </div>
          <p>
            <strong>Fought against activist ballot initiatives, including:</strong>
            <ul>
              <li>Organizing a comprehensive litigation strategy challenging the California Proposition 12 and similar ag initiatives, such as Question 3 in Massachusetts.</li>

              <li>Successfully pushing for the Supreme Court to hear NPPC’s case against California Proposition 12. </li>
            </ul>
          </p>

          <div class="PhotoOnRight">
            <img src="../assets/supreme-court.jpg" alt="Supreme Court">
          </div>
          <p>Aggressively <strong>campaigned against a decrease in plant line speeds,</strong> culminating in a November 2021 announcement from the USDA about a pilot program to boost future harvest capacity by restoring previous line speed rates.  </p>

          <div class="PhotoOnLeft">
            <img src="../assets/pork-speed-line.jpg" alt="Pork speed line">
          </div>
          <p>
            Successfully <strong>advocated for funding</strong> for the <strong>National Animal Health Laboratory Network</strong> (NAHLN) through appropriations legislation.
          </p>

          <!-- START: Swoosh footer -->
          <div id="prefooter">
            <p>See everything we do for pork producers and the industry.</p>

            <a href="https://nppc.org"><button>Visit the new NPPC</button></a>

          </div>
          <div id="footer">
            <p><a onclick="window.scrollTo(0, 0);">Back to top <img src="../assets/ic_arrow_upward_24px.png" alt="Up!"></a></p>
            <div class="postfooter">
              <p>&#169; National Pork Producers Council</p>
              <p>All Rights Reserved · <a class="postfooterlink" href="https://nppc.org/terms-of-use/">Terms of Use</a> · <a class="postfooterlink" href="https://nppc.org/privacy-statement/">Privacy Policy</a></p>
            </div>
          </div>
          <!-- END: Swoosh footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketCommerce',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .content {
    background-color: #FED141;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    color: white;
    margin-top:-45px;
    min-width: 392px;
  }
  .MarketCommerce {
    margin: 0px;
  }
  h1{
    color: #755900;
    text-align: left;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 46pt;
    padding-top: 6vw;
    z-index: 2;
  }
  p{
    color: #755900;
    text-align: left;
    margin-top: 6vh;
    margin-bottom: 6vh;
    padding-left: 4vw;
    padding-right: 6vw;
    font-size: 28pt;
    line-height: 55px;
  }
  #pageLogo {
    position: relative;
    top: -15vh;
    right: -30vw;
    z-index: 1;
  }
  .firstImage {
    margin-top: -15vh;
  }
  .PhotoOnLeft{
    text-align: left;
  }
  .PhotoOnLeft img {
    border-bottom-right-radius:250px;
    border-top-right-radius:250px;
    width: 80vw;
  }
  .PhotoOnRight{
    text-align: right;
  }
  .PhotoOnRight img {
    border-bottom-left-radius:250px;
    border-top-left-radius:250px;
    width: 80vw;
  }
  @media only screen and (min-width: 1000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:350px;
      border-top-left-radius:350px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:350px;
      border-top-right-radius:350px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 1500px) {
    .PhotoOnRight img {
      border-bottom-left-radius:450px;
      border-top-left-radius:450px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:450px;
      border-top-right-radius:450px;
      width: 80vw;
    }
  }
  @media only screen and (min-width: 2000px) {
    .PhotoOnRight img {
      border-bottom-left-radius:550px;
      border-top-left-radius:550px;
      width: 80vw;
    }
    .PhotoOnLeft img {
      border-bottom-right-radius:550px;
      border-top-right-radius:550px;
      width: 80vw;
    }
  }
</style>