import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
Vue.use(VueRouter)

import InternationalTrade from './components/InternationalTrade.vue';
import EnvironmentEnergy from './components/EnvironmentEnergy.vue';
import MarketCommerce from './components/MarketCommerce.vue';
import FoodSafety from './components/FoodSafety.vue';
import StrategicPlan from './components/StrategicPlan.vue';


const routes = [
{ path: '/', component: InternationalTrade },
{ path: '/InternationalTrade', component: InternationalTrade },
{ path: '/EnvironmentEnergy', component: EnvironmentEnergy },
{ path: '/MarketCommerce', component: MarketCommerce },
{ path: '/WellBeing', component: FoodSafety },
{ path: '/StrategicPlan', component: StrategicPlan }
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
